<template>
    <div class="reports pt-2">
        <div>
            <h3 class="float-left">Отчети</h3>
            <div class="float-right">
                <b-button  size="sm" variant="outline-danger" @click="$router.push({name: 'devices'})">
                    <b-icon icon="arrow-left-circle-fill" font-scale="1"></b-icon> Назад
                </b-button>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="mt-2">
            <b-form @submit="onSubmit" @reset="onReset"  inline>
                <label class="mr-sm-2" for="inline-form-custom-select-pref">Период на отчета</label>
                <b-form-select
                id="inline-form-custom-select-pref"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="[{ text: 'Избери...', value: null }, {text: 'Дневен', value: 'day_detailed'}, {text: 'Месечен', value: 'month'}, {text: 'Годишен', value: 'year'}]"
                v-model="form.reportType" size="sm"
                ></b-form-select>

                <label class="sr-only" for="inline-form-input-startdate">Начало</label>
                <b-form-datepicker
                id="inline-form-input-startdate"
                class="mb-2 mr-sm-2 mb-sm-0  form-control-sm"
                v-model="form.startDate"
                placeholder="Дата" size="sm"
                ></b-form-datepicker>

                <b-button variant="primary" type="submit" :disabled="isLoading == true" size="sm"><b-icon-search scale="1"></b-icon-search> Покажи</b-button>
                <b-button variant="danger" type="reset" size="sm" class="ml-2"><b-icon-arrow-bar-left></b-icon-arrow-bar-left> Изчисти</b-button>
            </b-form>
            <div class="infoBox mt-2">
                <b-alert variant="warning" show fade v-if="isLoading">Зареждане на отчета, моля изчакайте ...</b-alert>
                <b-alert variant="danger" show fade v-if="isError">Грешка: {{ isError }}</b-alert>
                <b-alert variant="secondary" show fade v-if="!result.length && showResultTable && !isLoading">Няма намерени резултати</b-alert>
            </div>
                            
            <div v-if="result.length && showResultTable" class="report-results">
                <h6>Графика</h6>
                <div class="chart">
                    <BarChart v-if="chartType == 'bar'" :data=this.result />
                    <ReportsLineChart class="reportChart" v-if="chartType == 'line'" :data=this.result :deviceId=this.deviceId />
                </div>
                <h6 mt-4 v-if="resultTotal">Тотално производство за избрания период: <strong>{{ this.resultTotal | round(2) }} kWh</strong></h6>
                <h6 mt-2 v-if="resultTotal && form.reportType !== 'day_detailed'">Средно производство за избрания период: <strong>{{ this.resultAVG | round(2) }} kWh</strong></h6>
                <h6 mt-2 v-if="chartType == 'bar'">Таблица</h6>
                <div v-if="chartType == 'bar'" id="table">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                            <th scope="col">Период</th>
                            <th scope="col">Стойност</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in result" :key="row.date">
                                <th scope="row"> {{ row.date }}</th>
                                <td>{{ row.value }} KW/h</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <b-button v-if="showResultTable" class="mt-2" size="sm" variant="outline-danger" @click="$router.push({name: 'devices'})">
            <b-icon icon="arrow-left-circle-fill" font-scale="1"></b-icon> Назад
        </b-button>
    </div>
</template>

<script>
import axios from '../../middleware/axios'
import BarChart from '../charts/BarChart.vue'
import ReportsLineChart from '../charts/ReportsLineChart.vue'


export default {
    name: "Reports",
    props: ['id'],
    components: {
        BarChart,
        ReportsLineChart
    },
    data() {
        return {
            form: {
                reportType: null,
                startDate: new Date().getFullYear() + "-" + ('0' + (new Date().getMonth()+1)).slice(-2) + "-" + ('0' + new Date().getDate()).slice(-2)
            },
            isLoading: false,
            isError: "",
            result: [],
            resultTotal: false,
            resultAVG: false,
            showResultTable: false,
            chartType: 'bar',
            deviceId: 'X'
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()

            this.isError = ""
            this.showResultTable = false
            
            console.log(this.form);
            
            if(this.form.reportType !== null && this.form.startDate !== '') {
                if(this.form.reportType === 'day_detailed') {
                    this.chartType = 'line'
                } else {
                    this.chartType = 'bar'
                }
                //Input date is present, continue
                this.isLoading = true

                const bodyParameters = this.form;

                axios.post( 'https://solareye.ntx.bg:9091/devices/' + this.id + '/reports/', bodyParameters )
                .then(res => {
                    console.log(res.data)
                    if(res.data) {
                        this.result = res.data.raw
                        this.resultTotal = res.data.total
                        this.resultAVG = res.data.total / res.data.raw.length
                        this.showResultTable = true
                    }
                    this.isLoading = false
                    this.isError = ''
                })
                .catch(err => {
                    this.showResultTable = false
                    this.isError = err 
                    this.isLoading = false  
                    console.log(err);        
                })

            } else {
                this.isError = "Моля изберете тип на отчета и дата."
            }
        },
        onReset(event) {
            event.preventDefault()
            this.isError = "";
            this.form.startDate = ''
            this.form.reportType = null
            this.result = []
        },
    },
    mounted() {
    },
    filters: {
        round: function(value, decimals) {
            if(!value) {
                value = 0;
            }

            if(!decimals) {
                decimals = 0;
            }

            value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
            return value;
        }
    }
}

</script>

<style scoped>
.reportChart {
    height: 250px;
}
</style>